import nookies from 'nookies';
import { mobileVendor } from 'react-device-detect';
import getConfig from 'next/config';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';

const {
  publicRuntimeConfig: { DOMAIN_URL, DYNAMIC_DOMAIN, ENVIRONMENT },
} = getConfig();

export const disablePageScroll = (disableScroll = true) => {
  document.body.style.overflow = disableScroll ? 'hidden' : 'auto';
  document.body.style['-ms-overflow-style'] = disableScroll ? 'none' : 'auto';
};

export const numberFormat = (value) =>
  new Intl.NumberFormat('en-SG', {
    style: 'currency',
    currency: 'SGD',
  }).format(value);

export const getDomainForCookies = (domainArg) => {
  let domainTreat;

  if (!domainArg) {
    if (typeof window === 'undefined') {
      return '';
    }
    domainTreat = window.location.hostname;
  } else {
    domainTreat = domainArg;
  }

  let cookieDomain = '';
  const ipRegex =
    /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/g;

  if (
    typeof domainTreat !== 'undefined' &&
    domainTreat !== 'localhost' &&
    !ipRegex.test(domainTreat)
  ) {
    const removeSubdomain = (domain) => {
      let wholeDomain = domain;
      const isCom = wholeDomain.match(/\.com\./);
      wholeDomain = wholeDomain.split('.');
      wholeDomain = wholeDomain.slice(isCom ? -3 : -2);
      wholeDomain = wholeDomain.join('.');
      return wholeDomain;
    };
    cookieDomain = `.${removeSubdomain(domainTreat)}`;
  }
  return cookieDomain;
};

export const authTokenCookiOptions = (domain) => {
  const AUTH_COOKIE_MAX_AGE_IN_S = 30 * 24 * 60 * 60; // 30 days in seconds
  return {
    path: '/',
    maxAge: AUTH_COOKIE_MAX_AGE_IN_S,
    sameSite: 'lax',
    domain,
  };
};

export const getVoucherDeepLink = (code) => {
  const isPreviewMode = ENVIRONMENT === 'PREVIEW';
  const domain = isPreviewMode ? DYNAMIC_DOMAIN : DOMAIN_URL;
  const webLink = `${domain}/accounts?tab%3Dvouchers%26code%3D${code}`;
  const appLink = `fairpriceonline://loyalty/vouchers/add?code%253D${code}`;
  const appHuaweiLink = `fairpriceonline%3A%2F%2Floyalty%2Fvouchers%2Fadd%3Fcode%3D${code}`;
  const deeplink = `https://referral.fairprice.com.sg/?link=${webLink}%26deeplink%3D${appLink}&apn=com.fairprice.mcomapp&amv=271&ibi=com.ntuc.fairpriceonline&isi=1012365118`;
  const deeplinkHuawei = `https://fairprice.dra.agconnect.link/?deeplink=${webLink}&android_deeplink=${appHuaweiLink}&android_open_type=1&android_package_name=com.fairprice.mcomapp&campaign_channel=Store+this+voucher&preview_type=2&landing_page_type=2&region_id=3`;

  return mobileVendor === 'Huawei' ? deeplinkHuawei : deeplink;
};

const ecommAppLink = (text) => {
  const links = {
    'Browse promos': 'deals?tag=',
    'Shop now': 'home',
  };

  return links[text] || 'https://www.gift.fairprice.com.sg/';
};

const ecommWebLink = (text) => {
  const links = {
    'Browse promos': 'https://www.fairprice.com.sg/promotions',
    'Shop now': 'https://www.fairprice.com.sg/',
  };

  return links[text] || 'https://www.gift.fairprice.com.sg/';
};

export const getEcommVoucherLink = (text) => {
  const webLink = ecommWebLink(text);
  const appLink = `fairpriceonline://${ecommAppLink(text)}`;
  return `https://referral.fairprice.com.sg/?link=${webLink}?deeplink%3D${appLink}&apn=com.fairprice.mcomapp&amv=313&ibi=com.ntuc.fairpriceonline&isi=1012365118`;
};

export const getToggleLabel = () => {
  switch (window?.location?.pathname || '') {
    case '/':
      return 'home';
    case '/cart/':
      return 'cart';
    case '/customise-voucher/':
      return 'customize selection';
    default:
      return '';
  }
};

export const findCategory = (value, voucherMetaData = []) => {
  const imgindex = voucherMetaData.findIndex((d) => {
    return d.png === value;
  });
  let category;
  if (imgindex >= 0) {
    category = voucherMetaData[imgindex].name;
  }
  return category;
};

export const downloadPDF = (data, pdfRef) => {
  const orderDate = new Date(data.createTime);
  const DD = String(orderDate.getDate()).padStart(2, '0');
  const MM = String(orderDate.getMonth() + 1).padStart(2, '0');
  const YYYY = orderDate.getFullYear();
  const orderCreateDate = DD + MM + YYYY;
  const input = pdfRef.current;
  html2canvas(input).then((canvas) => {
    const imgData = canvas.toDataURL('image/png');
    const pdf = jspdf('p', 'mm', 'a4', true);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const imgWidth = canvas.width;
    const imgHeight = canvas.height;
    const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
    const imgX = (pdfWidth - imgWidth * ratio) / 2;
    const imgY = 30;
    pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
    pdf.save(`FPG_${data.reference}_${orderCreateDate}.pdf`);
  });
};

export const setCookie = (name, value) => {
  nookies.destroy({}, name);
  const options = {
    path: '/',
  };
  nookies.set(null, name, value, options);
};

export const getCookie = (name, ctx) => {
  const cookies = nookies.get(ctx);
  return cookies[name];
};

export const deleteCookie = (name) => {
  nookies.destroy({}, name, { path: '/' });
};

export const safeJSONParse = (value, fallback = null) => {
  try {
    return JSON.parse(value);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Failed to parse JSON:', error);
    return fallback;
  }
};

export const isIosDevice = () => {
  const { userAgent } = navigator;
  return /iPad|iPhone|iPod/.test(userAgent);
};

export const sortArrayByField = (arr, field, order = 'desc') => {
  return arr.sort((a, b) => {
    const valueA = new Date(a[field]);
    const valueB = new Date(b[field]);

    const isDateAValid = !Number.isNaN(valueA.getTime()); // Check if valueA is a valid date
    const isDateBValid = !Number.isNaN(valueB.getTime()); // Check if valueB is a valid date

    const actualValueA = isDateAValid ? valueA : a[field]; // If it's not a valid date, use the original field value
    const actualValueB = isDateBValid ? valueB : b[field];

    if (order === 'desc') {
      return actualValueA < actualValueB ? 1 : -1;
    }
    return actualValueA > actualValueB ? 1 : -1;
  });
};
